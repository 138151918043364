
import { defineComponent, onMounted, onUnmounted } from 'vue'

export default defineComponent({
  name: 'error-401',
  components: {},
  setup () {
    onMounted(() => {
    })

    onUnmounted(() => {
    })

    return {
    }
  }
})
